import React from 'react';
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import { Galeri, Tentangkami, Jasakami, Kontak } from './components/pages';

function App() {
  return (
    <div className="body">
      <Router>
      <Navbar />
      <Routes>
        <Route exact path='/' exact element={<Tentangkami />} />
        <Route path='/tentang-kami' element={<Tentangkami/>} />
        <Route path='/jasa-kami' element={<Jasakami/>} />
        <Route path='/galeri' element={<Galeri/>} />
        <Route path='/kontak' element={<Kontak/>} />
      </Routes>
      </Router>
    </div>
    
  );
}

export default App;
