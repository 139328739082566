import React, { Component } from 'react';
import './gallery.css'
import image1 from "../galeri/1.jpg";
import image2 from "../galeri/2.jpg";
import image3 from "../galeri/3.jpg";
import image4 from "../galeri/4.jpg";
import image5 from "../galeri/5.jpg";
import image6 from "../galeri/6.jpg";
import image7 from "../galeri/7.jpg";
import image8 from "../galeri/8.jpg";
import image9 from "../galeri/9.jpg";
import image10 from "../galeri/10.jpg";
import image11 from "../galeri/11.jpg";
import image12 from "../galeri/12.jpg";
import image13 from "../galeri/13.jpg";
import image14 from "../galeri/14.jpg";
import image15 from "../galeri/15.jpg";
import image16 from "../galeri/16.jpg";
import image17 from "../galeri/17.jpg";
import image18 from "../galeri/18.jpg";
import image19 from "../galeri/19.jpg";
import image20 from "../galeri/20.jpg";
import image21 from "../galeri/21.jpg";


class Gallery extends Component {   


    state = {
        index: 0, 
        picList: [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, 
            image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21]
      }
      
      onClickNext= () => {
          if (this.state.index + 1 === this.state.picList.length ){
              this.setState({ 
                  index: 0 
                })
            } else {
                this.setState({
                    index: this.state.index + 1
                })
            }

          }
          onClickPrevious= () => {
            if (this.state.index - 1 === -1 ){
                this.setState({ 
                    index: this.state.picList.length - 1
                  })
              } else {
                  this.setState({
                      index: this.state.index - 1
                  })
              }
            }
      
      render() {
        return (
          <div className="galeri-div">
            <img src={this.state.picList[this.state.index]} className="galeri-img" /> <br/>
            <div className="galeri-button">
                <button style={{"fontSize":"18px"}} onClick={this.onClickPrevious}> Previous </button>
                <button style={{"margin-left":"5px", "fontSize":"18px"}} onClick={this.onClickNext}> Next </button>
            </div>
          </div>
        );
      }

}


export default Gallery;