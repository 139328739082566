import React from "react";
import './pages.css';
import Gallery from "./gallery";

export function Tentangkami() {
    return <div className="tentangkami">
            <h1 className="title">
                Tentang Kami
            </h1>
            <p className="text">
                Kami kontraktor tiang pancang beton yang menyediakan tiang pancang berukuran 20 x 20 (cm) dan 25 x 25 (cm). Saat ini
                kami melayani pemasangan tiang pancang di lokasi Jakarta, Tanggerang, Depok, Bogor dan Bekasi.
            </p>
            <p className="text">
                Selain tiang pancang, kami juga melaksanakan pembuatan borpile berukuran diameter 30 cm, 40 cm, 50 cm, dengan maksimum 80 cm.
            </p>
        </div>
}

export function Jasakami() {
    return <div className="jasakami">
            <h1 className="title">
                Jasa Kami
            </h1>
            <p className="text">
                Sebagai salah satu jenis Pondasi Gedung Perkantoran, Rumah Tinggal, Ruko, Rukan, Pergudangan, maka sistem Pondasi Tiang Pancang Mini
                ternyata mampu menjawab kebutuhan konsumen.
            </p>
            <p className="text">
                Segmen Tiang Pancang yang digunakan adalah 6 meter dan 3 meter dengan sambungan antar Tiang Pancang menggunakan Las Listrik
                pada Pelat Penyambung Tiang Pancang.
            </p>
            <p className="text">
                Kegunaan dari Pondasi ini selain memberikan Daya Dukung yang baik, juga menjaga bangunan agar mengalami penurunan sekecil mungkin
                dan seimbang.
            </p>
            <a className="jasakamifot1" href="/">
                <img src={require("../galeri/21.jpg")} className="jasakamifot"  alt="jasakamifot1" width="700px" height="" />
            </a>
            <p className="text">
                Dalam proses pembuatan Tiang Pancang Minipile digunakan Beton Readymix dengan Mutu Beton K - 450 dan sistem pengecoran
                yang digunakan adalah Precast Concrete dengan dimensi :
            </p>
            <p className="text-jasakami-bawah">
            □ 20 cm x 20 cm
            </p>
            <p className="text">
            □ 25 cm x 25 cm
            </p>
            <a className="jasakamifot2" href="/">
                <img src={require("../galeri/14.jpg")} className="jasakamifot"  alt="jasakamifot2" width="700px" height="" />
            </a>
            <p className="text">
            Sebagai Pondasi Alternatif dapat juga dilaksanakan Mini Borpile dengan diameter 30 cm, 40 cm, 50 cm, maksimum 80 cm dan kedalaman sampai
            anah Keras atau maksimal 30 meter.
            </p>
            <p className="text">
            Khusus untuk diameter 60 cm kedalaman maksimum adalah 20 meter. Pada umumnya pembesian dan mutu beton yang digunakan sesuai perencanaan
            kami, untuk beton lebih representatif dengan Readymix dan pada lokasi yang sempit dapat dipergunakan Beton Sitemix dengan mutu beton yang
            umum digunakan adalah K-225, K-250, atau maksimum K-300.
            </p>
            <a className="jasakamifot3" href="/">
                <img src={require("../galeri/13.jpg")} className="jasakamifot"  alt="jasakamifot3" width="300px" height="" />
            </a>
        </div>
}


export function Galeri() {
    return <div className="galeri">
            <Gallery />
        </div>
}

export function Kontak() {
    return <div className="kontak">
        <h1 className="title">
                Kontak
            </h1>
            <p className="text-kontak">
                Tel:
            </p>
            <p className="text-kontak-bawah">
                087788894445
            </p>
            <p className="text-kontak">
                Email: 
            </p>
            <p className="text-kontak-bawah">
                indopondasi@gmail.com
            </p>
        </div>
}
