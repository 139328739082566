import React, { Component } from 'react';
import { MenuItems, MobileItems } from "./Menuitems"
import './Navbar.css'


class Navbar extends Component {
    state = { clicked: false }

    handleClick = () => {
        
        alert("clicked");
    }

    render() {
        return(
            <nav className="NavbarItems">
                
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>
                {/*<ul className={this.state.clicked ? 'mobile-menu active' : 'mobile-menu'}>
                    {MobileItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>*/}
                <a className="a-logo" href="/">
                    <img src={require("../../galeri/logo.png")} className="navbar-logo"  alt="logo" width="220px" height="" />
                </a>
            </nav>
        )
    }
}

export default Navbar