export const MenuItems = [
    {
        title: 'Tentang Kami',
        url: 'tentang-kami',
        cName: 'nav-links'
    },
    {
        title: 'Jasa Kami',
        url: 'jasa-kami',
        cName: 'nav-links'
    },
    {
        title: 'Galeri',
        url: 'galeri',
        cName: 'nav-links'
    },
    {
        title: 'Kontak',
        url: 'kontak',
        cName: 'nav-links'
    }
]

export const MobileItems = [
    {
        title: 'Tentang Kami',
        url: 'tentang-kami',
        cName: 'mobile-links'
    },
    {
        title: 'Jasa Kami',
        url: 'jasa-kami',
        cName: 'mobile-links'
    },
    {
        title: 'Galeri',
        url: 'galeri',
        cName: 'mobile-links'
    },
    {
        title: 'Kontak',
        url: 'kontak',
        cName: 'mobile-links'
    }
]